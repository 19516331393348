import { createStore } from "vuex";
import axios from 'axios'
import { AggregatedData, AggregatedDataRequest, DataPoint, YieldData, YieldDataRequest } from "@/types/backend";
import { getFieldUnit } from "@/utils/fieldSettings";

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: true,
    sidebarUnfoldable: false,
    theme: 'dark',
    resizing: false,
    resizingUpdate: new Date(),
    fields: [],
    liveData: [] as DataPoint[],
    liveDataUpdate: Date.now(),
    aggregatedData: {} as { [key: string]: AggregatedData },
    aggregatedDataLoading: {} as { [key: string]: boolean },
    aggregatedDataRequest: {} as { [key: string]: AggregatedDataRequest },
    yieldData: {} as { [key: string]: YieldData },
    yieldDataLoading: {} as { [key: string]: boolean },
    yieldDataRequest: {} as { [key: string]: YieldDataRequest },
  },
  getters: {
    isDarkTheme(state) {
      return state.theme === 'dark'
    },
    isResizing(state) {
      return state.resizing
    },
    resizingUpdate(state) {
      return state.resizingUpdate
    },
    aggregatedDataRequestByIdentifier: (state) => (identifier: string) => {
      return state.aggregatedDataRequest[identifier] || {
        field: 'P_AC',
        device: '',
        inverterName: '',
        channel: 'ch0',
        rangeStart: new Date(),
        rangeStop: new Date(),
      }
    },
    aggregatedDataByIdentifier: (state) => (identifier: string) => {
      return state.aggregatedData[identifier] || { times: [], values: [] }
    },
    aggregatedDataLoadingByIdentifier: (state) => (identifier: string) => {
      return state.aggregatedDataLoading[identifier]
    },
    yieldDataRequestByIdentifier: (state) => (identifier: string) => {
      return state.yieldDataRequest[identifier] || {
        field: 'P_AC',
        device: '',
        inverterName: '',
        channel: 'ch0',
        rangeStart: new Date(),
        rangeStop: new Date(),
        cumulateBy: [],
        window: '1h',
      }
    },
    yieldDataByIdentifier: (state) => (identifier: string) => {
      return state.yieldData[identifier] || { data: [], identifier: '' }
    },
    yieldDataLoadingByIdentifier: (state) => (identifier: string) => {
      return state.yieldDataLoading[identifier]
    }
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    setResizing(state, value: boolean) {
      state.resizing = value
      if (!value) {
        state.resizingUpdate = new Date()
      }
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setFields(state, payload) {
      state.fields = payload
    },
    setLiveData(state, payload: DataPoint[]) {
      state.liveData = payload
      state.liveDataUpdate = Date.now()
    },
    setAggregatedData(state, payload: { [key: string]: AggregatedData }) {
      state.aggregatedData = {
        ...state.aggregatedData,
        ...payload
      }
    },
    setAggregatedDataRequest(state, payload: { [key: string]: AggregatedDataRequest }) {
      state.aggregatedDataRequest = {
        ...state.aggregatedDataRequest,
        ...payload
      }
    },
    setAggregatedDataLoading(state, payload: { [key: string]: boolean }) {
      state.aggregatedDataLoading = {
        ...state.aggregatedDataLoading,
        ...payload
      }
    },
    setYieldData(state, payload: { [key: string]: YieldData }) {
      state.yieldData = {
        ...state.yieldData,
        ...payload
      }
    },
    setYieldDataLoading(state, payload: { [key: string]: boolean }) {
      state.yieldDataLoading = {
        ...state.yieldDataLoading,
        ...payload
      }
    },
    setYieldDataRequest(state, payload: { [key: string]: YieldDataRequest }) {
      state.yieldDataRequest = {
        ...state.yieldDataRequest,
        ...payload
      }
    }
  },
  actions: {
    /**
     * Load aggregated data from backend
     * @param store
     * @param user
     */
    async loadFields(store, user: string): Promise<void> {
      const { data } = await axios.get(
        `/api/query/${user}/fields`,
      )
      store.commit('setFields', data)
    },

    /**
     * Load aggregated data from backend
     * @param store
     * @param user
     */
    async loadLiveData(store, user: string): Promise<void> {
      const { data } = await axios.get<DataPoint[]>(
        `/api/query/${user}/last`,
      )
      store.commit('setLiveData', data)
    },

    /**
     * Load aggregated data from backend
     * @param store
     * @param req
     */
    async loadYieldData(store, req: { user: string, identifier: string }): Promise<void> {
      const request = store.getters.yieldDataRequestByIdentifier(req.identifier) as YieldDataRequest

      // commit request params to store
      store.commit('setYieldDataRequest', {
        [req.identifier]: request
      });

      try {
        const { data } = await axios.post(
          `/api/query/${req.user}/yield`,
          {
            field: request.field,
            channel: 'ch0',
            rangeStart: request.rangeStart?.toISOString(),
            rangeStop: request.rangeStop?.toISOString(),
            interval: '1d',
            cumulateBy: request.cumulateBy,
            window: request.window,
          },
        )

        // commit response to store
        store.commit('setYieldData', {
          [req.identifier]: {
            identifier: req.identifier,
            lastTime: Date.now(),
            data
          }
        })
      } catch (e) {
        // check if there is already data in store
        const data = store.getters.yieldDataByIdentifier(req.identifier).data
        if (!data.length) {
          return;
        }
        // else only set lastTime to now
        store.commit('setYieldData', {
          [req.identifier]: {
            identifier: req.identifier,
            lastTime: Date.now(),
            data
          }
        })
      } finally {
        store.commit('setYieldDataLoading', {
          [req.identifier]: false
        });
      }
    },

    /**
     * Load aggregated data from backend
     * @param store
     * @param req
     */
    async loadAggregatedData(store, req: { user: string, identifier: string }): Promise<void> {
      // get request params from store with identifier as key.
      // if no request params are stored, commit empty object
      const request = store.getters.aggregatedDataRequestByIdentifier(req.identifier)

      // commit request params to store
      store.commit('setAggregatedDataRequest', {
        [req.identifier]: request
      });

      try {
        // send request to backend
        const { data } = await axios.post(
          `/api/query/${req.user}/aggregated`,
          {
            field: request.field,
            device: request.device,
            inverterName: request.inverterName,
            channel: request.channel,
            rangeStart: request.rangeStart,
            rangeStop: request.rangeStop,
            cumulateBy: request.cumulateBy,
          },
        )

        // commit response to store
        store.commit('setAggregatedData', {
          [req.identifier]: {
            field: request.field,
            unit: getFieldUnit(request.field),
            identifier: req.identifier,
            lastTime: Date.now(),
            ...data
          }
        })
      } catch (e) {
        // check if there is already data in store
        const data = store.getters.aggregatedDataByIdentifier(req.identifier)
        if (!data) {
          return;
        }
        // else only set lastTime to now
        store.commit('setAggregatedData', {
          [req.identifier]: {
            ...data,
            lastTime: Date.now()
          }
        })
      } finally {
        store.commit('setAggregatedDataLoading', {
          [req.identifier]: false
        });
      }
    }
  },
  modules: {},
})
