
export const isFieldHidden = (field?: string): boolean => {
  const hiddenFields = [
    'ALARM_MES_ID',
    'LastAlarmCode',
    'F_AC',
    'PF_AC',
    'I_AC',
    'Q_AC',
    'U_AC',
    'Name'
  ] as string[]

  return hiddenFields.includes(field || '')
}

export const getFieldUnit = (field?: string): string => {
  const fieldUnits = {
    'Efficiency': '%',
    'Irradiation': 'W/m²',
    // 'F_AC': 'Hz',
    // 'I_AC': 'A',
    'P_AC': 'W',
    // 'U_AC': 'V',
    'I_DC': 'A',
    'P_DC': 'W',
    'U_DC': 'V',
    'Temp': '°C',
    'YieldDay': 'Wh',
    'YieldTotal': 'kWh',
  } as { [key: string]: string }

  return fieldUnits[field || ''] || ''
}
