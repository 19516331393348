
import { CContainer } from '@coreui/vue-pro'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    CContainer,
  },
}
